<template>
  <div>
    <router-view></router-view>
    <ScrollTop />
  </div>
</template>

<script>
import ScrollTop from "@/components/ScrollTop.vue";
export default {
  components: { ScrollTop },
};
</script>

<style></style>
